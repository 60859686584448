<template>
    <div class="sidebar-wrapper">
        <a href="javascript:" class="logo-route">
            <div class="logo-wrapper">
                <!-- <img src="../assets/images/book.png" class="logo-img" alt=""> -->
                <h2 class="title">TAX EBOOK</h2>
            </div>
        </a>
        <div class="sidebar-navigation">
            <CollapseMenu :items="items" :open="true" :horizontal="horizontal" :sidebarGroupTitle="sidebarGroupTitle"/>
            <ul>
                <li>
                    <router-link to="/dashboard">
                        <a href="">
                            <div>
                                <svg id="Layer_1" data-name="Layer 1"
                                    viewBox="0 0 100 100" style="fill:#fff;">
                                    <path class="cls-1" d="M50,21.25l25,22.5V82.8H65v-30H35v30H25v-39l25-22.5M50,7.8,2.87,50.21a1.49,1.49,0,0,0,1,2.59H15v40H45v-30H55v30H85v-40H96.13a1.49,1.49,0,0,0,1-2.59Z"/>
                                </svg>
                            </div>
                            Dashboard
                            <!-- <i class="ri-arrow-down-s-line cus-arrow"></i> -->
                        </a>
                    </router-link>
                </li>
                <!-- <li>
                    <router-link to="/manage-book">
                        <a href="">
                            <div>
                                <svg id="Layer_1" data-name="Layer 1"
                                    viewBox="0 0 100 100" style="fill:#fff;">
                                    <path class="cls-1" d="M50,21.25l25,22.5V82.8H65v-30H35v30H25v-39l25-22.5M50,7.8,2.87,50.21a1.49,1.49,0,0,0,1,2.59H15v40H45v-30H55v30H85v-40H96.13a1.49,1.49,0,0,0,1-2.59Z"/>
                                </svg>
                            </div>
                        Manage Book
                        </a>
                    </router-link>
                </li> -->
                <li v-if="has_permission('user_management')" class="submenu" style="margin-left: 10px;">
                    <a href="#">
                      <i class="ri-folder-user-fill pr-2"></i>
                       User Management
                        <i class="ri-arrow-down-s-line cus-arrow"></i>
                    </a>
                    <ul class="sub-menu">
                        <!-- <li v-if="has_permission('permission')" style="margin-left: 50px;">
                            <router-link to="/permission">
                               <p>Permission</p>
                            </router-link>
                        </li> -->
                        <li v-if="has_permission('role')" style="margin-left: 50px;">
                            <router-link to="/role">
                                <p>Role</p>
                            </router-link>
                        </li>
                        <li v-if="has_permission('user')" style="margin-left: 50px;">
                            <router-link to="/user">
                                <p>User</p>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li v-if="has_permission('subscription_plan')">
                    <router-link to="/subscription-plan">
                        <a href="">
                          <i class="ri-building-3-fill pr-2"></i>
                        Subscription Plan
                            <!-- <i class="ri-arrow-down-s-line cus-arrow"></i> -->
                        </a>
                    </router-link>
                </li>
                <li v-if="has_permission('manage_book')" class="submenu" style="margin-left: 10px;">
                    <a href="#">
                        <i class="ri-git-repository-fill pr-2"></i>
                        Manage Book
                        <i class="ri-arrow-down-s-line cus-arrow"></i>
                    </a>
                    <ul class="sub-menu">
                        <li v-if="has_permission('section')" style="margin-left: 50px;">
                            <router-link to="/book-section">
                               <p>Section</p>
                            </router-link>
                        </li>
                        <li v-if="has_permission('book_page')" style="margin-left: 50px;">
                            <router-link to="/tag">
                               <p>Tag</p>
                            </router-link>
                        </li>
                        <li v-if="has_permission('book_page')" style="margin-left: 50px;">
                            <router-link to="/book-page">
                               <p>Book Page</p>
                            </router-link>
                        </li>
                        <li v-if="has_permission('book_cover_page')" style="margin-left: 50px;">
                            <router-link to="/book-cover-page">
                               <p>Book Cover Page</p>
                            </router-link>
                        </li>
                        <li v-if="has_permission('section_note')" style="margin-left: 50px;">
                            <router-link to="/section-note">
                               <p>Section Note</p>
                            </router-link>
                        </li>
                        <!-- <li style="margin-left: 50px;">
                            <router-link to="/section-note">
                               <p>Section Note</p>
                            </router-link>
                        </li> -->
                        <!-- <li style="margin-left: 50px;">
                            <router-link to="/chapters">
                               <p> Chapters</p>
                            </router-link>
                        </li>
                        <li style="margin-left: 50px;">
                            <router-link to="/sub-chapters">
                                <p>Subchapters</p>
                            </router-link>
                        </li> -->
                    </ul>
                </li>
                <li v-if="has_permission('control_notifications')">
                  <router-link to="/app-ui-features">
                    <a href="">
                      <i class="ri-smartphone-fill pr-2"></i>
                      App Ui Features
                    </a>
                  </router-link>
                </li>
                <li v-if="has_permission('control_notifications')">
                  <router-link to="/control-notification">
                    <a href="">
                      <i class="ri-notification-fill pr-2"></i>
                      Control Notifications
                    </a>
                  </router-link>
                </li>
                <li v-if="has_permission('faq')">
                  <router-link to="/faq">
                    <a href="">
                      <i class="ri-questionnaire-fill pr-2"></i>
                      Faq
                        <!-- <i class="ri-arrow-down-s-line cus-arrow"></i> -->
                    </a>
                  </router-link>
                </li>
                <li v-if="has_permission('terms_and_conditions_pages')">
                  <router-link to="/terms-and-conditions-page">
                    <a href="">
                        <div>
                            <svg id="Layer_1" data-name="Layer 1"
                                viewBox="0 0 100 100" style="fill:#fff;">
                                <path class="cls-1" d="M50,21.25l25,22.5V82.8H65v-30H35v30H25v-39l25-22.5M50,7.8,2.87,50.21a1.49,1.49,0,0,0,1,2.59H15v40H45v-30H55v30H85v-40H96.13a1.49,1.49,0,0,0,1-2.59Z"/>
                            </svg>
                        </div>
                      Terms & Conditions Pages
                        <!-- <i class="ri-arrow-down-s-line cus-arrow"></i> -->
                    </a>
                  </router-link>
                </li>
                <!-- <li>
                    <router-link to="/admin">
                        <a href="">
                            <div>
                                <svg id="Layer_1" data-name="Layer 1"
                                    viewBox="0 0 100 100" style="fill:#fff;">
                                    <path class="cls-1" d="M50,21.25l25,22.5V82.8H65v-30H35v30H25v-39l25-22.5M50,7.8,2.87,50.21a1.49,1.49,0,0,0,1,2.59H15v40H45v-30H55v30H85v-40H96.13a1.49,1.49,0,0,0,1-2.59Z"/>
                                </svg>
                            </div>
                        Admin
                            <i class="ri-arrow-down-s-line cus-arrow"></i>
                        </a>
                    </router-link>
                </li> -->
                <!-- <li v-if="has_permission('payment_media')">
                  <router-link to="/payment-media">
                    <a href="">
                        <div>
                            <svg id="Layer_1" data-name="Layer 1"
                                viewBox="0 0 100 100" style="fill:#fff;">
                                <path class="cls-1" d="M50,21.25l25,22.5V82.8H65v-30H35v30H25v-39l25-22.5M50,7.8,2.87,50.21a1.49,1.49,0,0,0,1,2.59H15v40H45v-30H55v30H85v-40H96.13a1.49,1.49,0,0,0,1-2.59Z"/>
                            </svg>
                        </div>
                      Payment Media
                    </a>
                  </router-link>
                </li> -->
                <li v-if="has_permission('vat_percent')">
                  <router-link to="/vat-percent">
                    <a href="">
                      <i class="ri-currency-fill pr-2"></i>
                      VAT Percent
                        <!-- <i class="ri-arrow-down-s-line cus-arrow"></i> -->
                    </a>
                  </router-link>
                </li>
                <li v-if="has_permission('invoice')">
                  <router-link to="/invoice">
                    <a href="">
                      <i class="ri-bill-fill pr-2"></i>
                      Invoice
                        <!-- <i class="ri-arrow-down-s-line cus-arrow"></i> -->
                    </a>
                  </router-link>
                </li>
                <li v-if="has_permission('subscriber')">
                  <router-link to="/subscriber">
                    <a href="">
                      <i class="ri-team-fill pr-2"></i>
                       Subscriber
                    </a>
                  </router-link>
                </li>
                <li v-if="has_permission('coupon')">
                    <router-link to="/coupon">
                        <a href="">
                          <i class="ri-coupon-fill pr-2"></i>
                         Coupon
                            <!-- <i class="ri-arrow-down-s-line cus-arrow"></i> -->
                        </a>
                    </router-link>
                </li>
                <!-- <li  v-if="has_permission('transaction')">
                    <router-link to="/payment">
                        <a href="">
                            <div>
                                <svg id="Layer_1" data-name="Layer 1"
                                    viewBox="0 0 100 100" style="fill:#fff;">
                                    <path class="cls-1" d="M50,21.25l25,22.5V82.8H65v-30H35v30H25v-39l25-22.5M50,7.8,2.87,50.21a1.49,1.49,0,0,0,1,2.59H15v40H45v-30H55v30H85v-40H96.13a1.49,1.49,0,0,0,1-2.59Z"/>
                                </svg>
                            </div>
                         Transaction
                        </a>
                    </router-link>
                </li> -->
                <!-- <li>
                    <router-link to="/cart">
                        <a href="">
                            <div>
                                <svg id="Layer_1" data-name="Layer 1"
                                    viewBox="0 0 100 100" style="fill:#fff;">
                                    <path class="cls-1" d="M50,21.25l25,22.5V82.8H65v-30H35v30H25v-39l25-22.5M50,7.8,2.87,50.21a1.49,1.49,0,0,0,1,2.59H15v40H45v-30H55v30H85v-40H96.13a1.49,1.49,0,0,0,1-2.59Z"/>
                                </svg>
                            </div>
                        Abandoned Cart
                        </a>
                    </router-link>
                </li> -->
            </ul>
        </div>
    </div>
</template>
<script>
import CollapseMenu from './menus/CollapseMenu.vue'
export default {
  Name: 'Sidebar',
  components: {
    CollapseMenu
  },
  mounted () {
  },
  data () {
    return {
      items: [],
      homeURL: { type: Object, default: () => ({ path: '/auth/dashboard' }) },
      logo: { type: String, default: require('../assets/images/logo.png') },
      horizontal: true,
      toggleButton: true,
      logoShow: true,
      onlyLogo: true,
      onlyLogoText: true,
      sidebarGroupTitle: true,
      menus: [
        {
          name: 'Module',
          children: [
            {
              name: 'Service',
              children: [
                {
                  name: 'Item One'
                },
                {
                  name: 'Item Two'
                },
                {
                  name: 'Item Three'
                }
              ]
            }
          ]
        }
      ]
    }
  }
}
</script>
<style>
/* Your custom styles here */
.sub-menu {
  display: none;
  list-style: none;
}

.submenu:hover .sub-menu {
  display: block;
}
</style>
